import {
    Action,
    AnyAction,
    configureStore,
    ThunkAction,
    ThunkDispatch,
    TypedStartListening,
} from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query/react"
import { authenticationApi } from "./services/authenticationApi"
import { publicApi } from "./services/publicApi"
import { authenticationSlice } from "./authenticationSlice"
import { userMetaSlice } from "./userMetaSlice"
import { badgesSlice, badgesListenerMiddleware } from "./badgesSlice"
import { createWrapper, Context } from "next-redux-wrapper"
import { nextReduxCookieMiddleware, wrapMakeStore } from "next-redux-cookie-wrapper"
import { rtkAuthentication401ErrorMiddleware } from "./middleware/rtkAuthentication401ErrorMiddleware"

const reducer = {
    // Slices
    [authenticationSlice.name]: authenticationSlice.reducer,
    [userMetaSlice.name]: userMetaSlice.reducer,
    [badgesSlice.name]: badgesSlice.reducer,

    // RTK APIs
    [authenticationApi.reducerPath]: authenticationApi.reducer,
    [publicApi.reducerPath]: publicApi.reducer,
}

const middleware = [
    badgesListenerMiddleware.middleware,
    authenticationApi.middleware,
    publicApi.middleware,
    rtkAuthentication401ErrorMiddleware,
]

export const makeSSGStore = () => {
    const store = configureStore({
        devTools: process.env.NODE_ENV !== "production",
        reducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
    })

    setupListeners(store.dispatch)

    return store
}

export const makePersistedStore = wrapMakeStore(() => {
    const store = configureStore({
        devTools: process.env.NODE_ENV !== "production",
        reducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .prepend(
                    nextReduxCookieMiddleware({
                        subtrees: [`${authenticationSlice.name}.authenticate`],
                    })
                )
                .concat(middleware),
    })

    setupListeners(store.dispatch)

    return store
})

export type AppStore = ReturnType<typeof makePersistedStore>
export type AppDispatch = AppStore["dispatch"]
export type RootState = ReturnType<AppStore["getState"]>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
export type AppStartListening = TypedStartListening<RootState, AppDispatch>

export const wrapper = createWrapper<AppStore>(makePersistedStore, { debug: true })

export const emptyGetStaticProps = wrapper.getStaticProps(() => () => ({
    props: {},
}))
