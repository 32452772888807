import {
    AnyAction,
    createListenerMiddleware,
    createSlice,
    ListenerEffectAPI,
    ThunkDispatch,
} from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "./rootState"
import { HYDRATE } from "next-redux-wrapper"
import { BadgeSchema, publicApi } from "./services/publicApi"
import { setAuthenticate } from "./authenticationSlice"

// TODO: (tech debt) move out of `packages/store` into `apps/students` - part of a wider "lets abstract redux" initiative

export interface Badges {
    issuedBadges: BadgeSchema[]
    pendingBadges: BadgeSchema[]
}

let initialState: Badges = {
    issuedBadges: [],
    pendingBadges: [],
}

export const badgesSlice = createSlice({
    name: "badges",
    initialState,
    reducers: {
        setIssuedBadges: (
            state,
            { payload: { issuedBadges } }: PayloadAction<{ issuedBadges: BadgeSchema[] }>
        ) => {
            state.issuedBadges = issuedBadges
        },
        setHasReadAtBadge: (
            state,
            { payload: { badgeId, readAt } }: PayloadAction<{ badgeId: string; readAt: string }>
        ) => {
            const index = state.issuedBadges.findIndex(
                (badge: BadgeSchema) => badge._id === badgeId
            )

            let badge = state.issuedBadges[index] as BadgeSchema
            badge.read_at = readAt

            state.issuedBadges = [
                ...(state.issuedBadges.slice(0, index) as BadgeSchema[]),
                badge,
                ...(state.issuedBadges.slice(
                    index + 1,
                    state.issuedBadges.length
                ) as BadgeSchema[]),
            ]
        },
        setPendingBadges: (
            state,
            { payload: { pendingBadges } }: PayloadAction<{ pendingBadges: BadgeSchema[] }>
        ) => {
            state.pendingBadges = pendingBadges
        },
        addPendingBadge: (
            state,
            { payload: { pendingBadge } }: PayloadAction<{ pendingBadge: BadgeSchema }>
        ) => {
            state.pendingBadges = [...state.pendingBadges, pendingBadge]
        },
    },
    extraReducers: {
        [HYDRATE]: (state, { payload }) => {
            const newState = {
                ...state,
                ...payload.badges,
            }

            return newState
        },
    },
})

export const { setIssuedBadges, setPendingBadges, setHasReadAtBadge, addPendingBadge } =
    badgesSlice.actions

export const selectAllBadges = (state: RootState) => state.badges.issuedBadges
export const selectLatestBadge = (state: RootState) =>
    state.badges.issuedBadges.length
        ? state.badges.issuedBadges.find((badge) => !badge.read_at) || null
        : null
export const selectPointsAndStarsAndBadges = (
    state: RootState
): { points: number; stars: number; badges: number } => {
    const points = state.badges.issuedBadges.reduce(
        (carry: number, badge: BadgeSchema) => carry + (badge.badge?.value || 0),
        0
    )

    return {
        points: points % 10,
        stars: Math.floor(points / 10),
        badges: state.badges.issuedBadges.length,
    }
}

const processPendingBadgesEffect = async (
    listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>,
    forceIsReady: boolean = false
) => {
    const rootState = listenerApi.getState() as RootState
    const { authenticate } = rootState.auth
    const { pendingBadges, issuedBadges } = rootState.badges

    if (authenticate === null) return

    const result = listenerApi.dispatch(
        publicApi.endpoints.createBadgesAsStudentPost.initiate({
            body: pendingBadges,
        })
    )
    result.unsubscribe()
    const response = await result

    listenerApi.dispatch(
        setIssuedBadges({
            issuedBadges: [...issuedBadges, ...response.data],
        })
    )

    listenerApi.dispatch(
        setPendingBadges({
            pendingBadges: [],
        })
    )
}

export const badgesListenerMiddleware = createListenerMiddleware()

badgesListenerMiddleware.startListening({
    actionCreator: addPendingBadge,
    effect: async (action, listenerApi) => {
        await processPendingBadgesEffect(listenerApi)
    },
})

badgesListenerMiddleware.startListening({
    actionCreator: setAuthenticate,
    effect: async (action, listenerApi) => {
        await processPendingBadgesEffect(listenerApi)
    },
})
