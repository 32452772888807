import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit"
import { setHasAuthentication401Error } from "../authenticationSlice"

export const rtkAuthentication401ErrorMiddleware: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        if (action?.payload?.status === 401) {
            api.dispatch(
                setHasAuthentication401Error({
                    hasAuthentication401Error: true,
                })
            )
        }

        return next(action)
    }
