import { emptyAuthenticationApi as api } from "./emptyAuthenticationApi"
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        loginAuthTokenByEmailPost: build.mutation<
            LoginAuthTokenByEmailPostApiResponse,
            LoginAuthTokenByEmailPostApiArg
        >({
            query: (queryArg) => ({
                url: `/auth/token-by-email`,
                method: "POST",
                body: queryArg.bodyLoginAuthTokenByEmailPost,
            }),
        }),
    }),
    overrideExisting: false,
})
export { injectedRtkApi as authenticationApi }
export type LoginAuthTokenByEmailPostApiResponse =
    /** status 200 Successful Response */ Authenticate
export type LoginAuthTokenByEmailPostApiArg = {
    bodyLoginAuthTokenByEmailPost: BodyLoginAuthTokenByEmailPost
}
export type LoginRedirectToUrlEnum =
    | "/"
    | "/students/journal/start"
    | "/students/dashboard"
    | "/students/setup/welcome"
    | "/dashboard/welcome"
    | "/dashboard"
    | "/plus-staff"
export type LoginRedirect = {
    url: LoginRedirectToUrlEnum
}
export type RoleEnum =
    | "public"
    | "student"
    | "teacher"
    | "headteacher"
    | "school_management"
    | "senco"
    | "plus_staff"
    | "premium"
export type Role = {
    role: RoleEnum
}
export type UserEssentialResponseModel = {
    _id?: string
    email: string
    username: string
    firstname: string
    lastname: string
    profile_image_url?: string
    role: Role
    school_id?: string
}
export type Authenticate = {
    access_token: string
    token_type: string
    redirect: LoginRedirect
    user: UserEssentialResponseModel
}
export type ValidationError = {
    loc: (string | number)[]
    msg: string
    type: string
}
export type HttpValidationError = {
    detail?: ValidationError[]
}
export type BodyLoginAuthTokenByEmailPost = {
    grant_type?: string
    username: string
    password: string
    scope?: string
    client_id?: string
    client_secret?: string
}
export const { useLoginAuthTokenByEmailPostMutation } = injectedRtkApi
