export interface BaseThemeColors {
    [key: string]: {
        main: string
        contrastText: string
    }
}

export const BaseThemeColors: BaseThemeColors = {
    transparentCream: {
        main: "rgba(0, 0, 0, 0.12)",
        contrastText: "rgba(255, 255, 255, 0.12)",
    },
    black: {
        main: "#363636",
        contrastText: "#FCF8F0",
    },
    green: {
        main: "#5CD66B",
        contrastText: "#fff",
    },
    orange: {
        main: "#FF641F",
        contrastText: "#fff",
    },
    blue: {
        main: "#1C5FCA",
        contrastText: "#fff",
    },
    blueDark: {
        main: "#1a33c5",
        contrastText: "#fff",
    },
    lilac: {
        main: "#F18AF9",
        contrastText: "#fff",
    },
    gold: {
        main: "#FFBE2C",
        contrastText: "#fff",
    },
    white: {
        main: "#FCF8F0",
        contrastText: "#363636",
    },
    grey: {
        main: "#7E8287",
        contrastText: "#FCF8F0",
    },
    red: {
        main: "red",
        contrastText: "#fff",
    },
}
