import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "./rootState"
import {
    UserEssentialResponseModel,
    Authenticate,
    BodyLoginAuthTokenByEmailPost,
} from "./services/authenticationApi"
import { HYDRATE } from "next-redux-wrapper"

export interface Authentication {
    user: UserEssentialResponseModel | null
    authenticate: Authenticate | null
    hasAuthentication401Error: boolean | null
}

let initialState: Authentication = {
    user: null,
    authenticate: null,
    hasAuthentication401Error: null,
}

/**
 * If not in production, see if `authenticate` has been attached to URL and configure our app to use it.
 * Why? Our developer setup does not run all `apps` under the same domain, so we cannot share state as easily.
 */
if (typeof window !== "undefined") {
    const url = location.search
    const query = url.substr(1)

    for (const part of query.split("&")) {
        var item = part.split("=")
        if (item[0] === "auth") {
            initialState.authenticate = JSON.parse(decodeURIComponent(item[1])) as Authenticate
            initialState.user = initialState?.authenticate?.user
        }
    }
}

export const authenticationSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthenticate: (
            state,
            { payload: { authenticate } }: PayloadAction<{ authenticate: Authenticate | null }>
        ) => {
            state.authenticate = authenticate
            state.user = authenticate?.user || null
        },
        setHasAuthentication401Error: (
            state,
            {
                payload: { hasAuthentication401Error },
            }: PayloadAction<{ hasAuthentication401Error: boolean | null }>
        ) => {
            state.hasAuthentication401Error = hasAuthentication401Error
        },
        logout: (state) => {
            state.authenticate = null
            state.user = null
        },
    },
    extraReducers: {
        [HYDRATE]: (state, { payload }) => {
            const newState = {
                ...state,
                ...payload.auth,
            }

            if (payload.auth && payload.auth.authenticate) {
                newState.user = payload.auth.authenticate.user
            }

            return newState
        },
    },
})

export const { setAuthenticate, setHasAuthentication401Error, logout } = authenticationSlice.actions
export const selectCurrentUser = (state: RootState) => state.auth.user
export const selectAuthenticate = (state: RootState) => state.auth.authenticate
export const selectHasAuthentication401Error = (state: RootState) =>
    state.auth.hasAuthentication401Error
