import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../rootState"
import { HYDRATE } from "next-redux-wrapper"
import {
    LoginAuthTokenByEmailPostApiArg,
    LoginAuthTokenByEmailPostApiResponse,
} from "./authenticationApi"

export const emptyAuthenticationApi = createApi({
    reducerPath: "authenticationApi",

    baseQuery: fetchBaseQuery({
        /**
         * Base URL - use rewrite (proxy) URL if present in ENV. Otherwise use normal endpoint.
         */
        baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL_AUTHENTICATION_PRODUCTION
            ? process.env.NEXT_PUBLIC_API_BASE_URL_AUTHENTICATION_PRODUCTION
            : process.env.NEXT_PUBLIC_BASE_DOMAIN +
              process.env.NEXT_PUBLIC_BASE_PATH +
              (typeof process.env.NEXT_PUBLIC_API_BASE_URL_AUTHENTICATION_REWRITE !== "undefined" &&
              process.env.NEXT_PUBLIC_API_BASE_URL_AUTHENTICATION_REWRITE
                  ? process.env.NEXT_PUBLIC_API_BASE_URL_AUTHENTICATION_REWRITE
                  : process.env.NEXT_PUBLIC_API_BASE_URL_AUTHENTICATION),

        /**
         * If we have a token, append to header.
         */
        prepareHeaders: (headers, { getState, endpoint }) => {
            const token = (getState() as RootState)?.auth?.authenticate?.access_token
            if (token) {
                headers.set("authorization", `Bearer ${token}`)
            }
            return headers
        },
    }),

    extractRehydrationInfo(action, { reducerPath }) {
        if (action.type === HYDRATE) {
            return action.payload[reducerPath]
        }
    },

    endpoints: (build) => ({
        /**
         * Overwrite to support FormData (regretfully RTK OpenAPI generator doesn't handle FormData)
         */
        loginAuthTokenByEmailPost: build.mutation<
            LoginAuthTokenByEmailPostApiResponse,
            LoginAuthTokenByEmailPostApiArg
        >({
            query: (queryArg) => {
                const formData = new FormData()
                formData.append("username", queryArg.bodyLoginAuthTokenByEmailPost.username)
                formData.append("password", queryArg.bodyLoginAuthTokenByEmailPost.password)
                return {
                    url: `/auth/token-by-email`,
                    method: "POST",
                    body: formData,
                }
            },
        }),
    }),
})
