import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "./rootState"
import { HYDRATE } from "next-redux-wrapper"
import { AvatarJson, UserMeta } from "./services/publicApi"

export interface UserMetaSlice {
    userMeta: UserMeta | null
}

let initialState: UserMetaSlice = {
    userMeta: null,
}

export const userMetaSlice = createSlice({
    name: "userMeta",
    initialState,
    reducers: {
        setUserMeta: (
            state,
            { payload: { userMeta } }: PayloadAction<{ userMeta: UserMeta | null }>
        ) => {
            state.userMeta = userMeta || null
        },
        addAvatarMetaIfNotExist: (
            state,
            { payload: { avatar } }: PayloadAction<{ avatar: AvatarJson }>
        ) => {
            state.userMeta = {
                meta: [
                    {
                        type: "Avatar",
                        value: avatar,
                    },
                    ...(state.userMeta !== null && typeof state.userMeta.meta !== "undefined"
                        ? state.userMeta.meta.filter((meta) => meta.type !== "Avatar")
                        : []),
                ],
            }
        },
    },
    extraReducers: {
        [HYDRATE]: (state, { payload }) => {
            return {
                ...state,
                ...payload.userMeta,
            }
        },
    },
})

export const { setUserMeta, addAvatarMetaIfNotExist } = userMetaSlice.actions

export const selectUserMeta = (state: RootState): UserMeta | null => state.userMeta.userMeta

export const selectAvatarJson = (state: RootState): AvatarJson | null => {
    if (
        state.userMeta.userMeta === null ||
        state.userMeta.userMeta.meta === null ||
        typeof state.userMeta.userMeta.meta === "undefined"
    )
        return null

    const metaItem = state.userMeta.userMeta.meta.find((item) => item.type === "Avatar")

    if (typeof metaItem === "undefined") return null

    return metaItem.value as AvatarJson
}

export const selectRedirect = (state: RootState): string | null => {
    if (
        state.userMeta.userMeta === null ||
        state.userMeta.userMeta.meta === null ||
        typeof state.userMeta.userMeta.meta === "undefined"
    )
        return null

    const metaItem = state.userMeta.userMeta.meta.find((item) => item.type === "Redirect")

    if (typeof metaItem === "undefined") return null

    return metaItem.value as string
}
