import * as React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import { Theme } from "@mui/material/styles"
import { ThemeProvider } from "@mui/material/styles"

export const CustomMuiThemeProvider = ({
    children,
    theme,
    fontStyleTag,
}: {
    children: React.ReactNode
    theme?: Theme
    fontStyleTag?: React.ReactNode
}) => {
    return (
        <React.Fragment>
            <CssBaseline />
            <style jsx global>{`
                @import url("https://fonts.googleapis.com/icon?family=Material+Icons");
            `}</style>
            {fontStyleTag}
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </React.Fragment>
    )
}
