import * as React from "react"
import { AppProps } from "next/app"
import Head from "next/head"
import Script from "next/script"
import * as amplitude from "@amplitude/analytics-browser"
import { CustomMuiThemeProvider } from "core/src/CustomMuiThemeProvider"
import { ThemeProvider } from "@mui/material/styles"
import { themeLight, fontStyleTag } from "core/src/ui/theme-curvy/theme"
import { Provider } from "react-redux"
import { wrapper } from "store/src/rootState"

function App({ Component, pageProps }: AppProps) {
    React.useEffect(() => {
        if (process.browser) {
            amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || "")
        }
    })

    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Head>
            <Script
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{ __html: process.env.NEXT_PUBLIC_GA4_HEAD_SCRIPT || "" }}
            />
            <CustomMuiThemeProvider theme={themeLight} fontStyleTag={fontStyleTag}>
                <ThemeProvider theme={themeLight}>
                    <Component {...pageProps} />
                </ThemeProvider>
            </CustomMuiThemeProvider>
        </>
    )
}

export default wrapper.withRedux(App)
