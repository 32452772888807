import { emptyPublicApi as api } from "./emptyPublicApi"
export const addTagTypes = [
    "me",
    "setup-account",
    "comment-ai",
    "user-meta",
    "stats",
    "reports",
    "resource-as-public",
    "forms-as-public",
    "school-invite-as-public",
    "resource-as-plus-staff",
    "user-as-plus-staff",
    "school-as-plus-staff",
    "misc-as-plus-staff",
    "user-as-school-management",
    "class-as-school-management",
    "student-as-school-management",
    "teacher-as-school-management",
    "school-settings-as-school-management",
    "journal-as-student",
    "questionnaire-as-student",
    "avatar-as-student",
    "badges-as-student",
    "unlocked-resources-as-student",
    "student-as-teacher",
    "class-as-teacher",
] as const
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getMeSettingsGet: build.query<GetMeSettingsGetApiResponse, GetMeSettingsGetApiArg>({
                query: () => ({ url: `/me/settings` }),
                providesTags: ["me"],
            }),
            updateMeSettingsPut: build.mutation<
                UpdateMeSettingsPutApiResponse,
                UpdateMeSettingsPutApiArg
            >({
                query: (queryArg) => ({
                    url: `/me/settings`,
                    method: "PUT",
                    body: queryArg.meSchema,
                }),
                invalidatesTags: ["me"],
            }),
            canSetupAccountSetupAccountSetupAccountUserIdGet: build.query<
                CanSetupAccountSetupAccountSetupAccountUserIdGetApiResponse,
                CanSetupAccountSetupAccountSetupAccountUserIdGetApiArg
            >({
                query: (queryArg) => ({
                    url: `/setup-account/setup-account/${queryArg.userId}`,
                    params: { login_hash: queryArg.loginHash },
                }),
                providesTags: ["setup-account"],
            }),
            setupAccountSetupAccountSetupAccountUserIdPut: build.mutation<
                SetupAccountSetupAccountSetupAccountUserIdPutApiResponse,
                SetupAccountSetupAccountSetupAccountUserIdPutApiArg
            >({
                query: (queryArg) => ({
                    url: `/setup-account/setup-account/${queryArg.userId}`,
                    method: "PUT",
                    body: queryArg.setupAccountModel,
                    params: { login_hash: queryArg.loginHash },
                }),
                invalidatesTags: ["setup-account"],
            }),
            postCommentAiPost: build.mutation<
                PostCommentAiPostApiResponse,
                PostCommentAiPostApiArg
            >({
                query: (queryArg) => ({
                    url: `/comment-ai`,
                    method: "POST",
                    body: queryArg.commentAiAnalysis,
                }),
                invalidatesTags: ["comment-ai"],
            }),
            getUserMetaGet: build.query<GetUserMetaGetApiResponse, GetUserMetaGetApiArg>({
                query: () => ({ url: `/user-meta` }),
                providesTags: ["user-meta"],
            }),
            sparklineStatsSparklineGet: build.query<
                SparklineStatsSparklineGetApiResponse,
                SparklineStatsSparklineGetApiArg
            >({
                query: (queryArg) => ({
                    url: `/stats/sparkline`,
                    params: {
                        filter_class_id: queryArg.filterClassId,
                        filter_student_id: queryArg.filterStudentId,
                    },
                }),
                providesTags: ["stats"],
            }),
            statsStatsPost: build.mutation<StatsStatsPostApiResponse, StatsStatsPostApiArg>({
                query: (queryArg) => ({
                    url: `/stats`,
                    method: "POST",
                    body: queryArg.bodyStatsStatsPost,
                    params: {
                        filter_by_sendco: queryArg.filterBySendco,
                        filter_by_premium: queryArg.filterByPremium,
                    },
                }),
                invalidatesTags: ["stats"],
            }),
            classReportReportsClassClassIdGet: build.query<
                ClassReportReportsClassClassIdGetApiResponse,
                ClassReportReportsClassClassIdGetApiArg
            >({
                query: (queryArg) => ({ url: `/reports/class/${queryArg.classId}` }),
                providesTags: ["reports"],
            }),
            studentInternalReportReportsStudentInternalStudentIdGet: build.query<
                StudentInternalReportReportsStudentInternalStudentIdGetApiResponse,
                StudentInternalReportReportsStudentInternalStudentIdGetApiArg
            >({
                query: (queryArg) => ({ url: `/reports/student-internal/${queryArg.studentId}` }),
                providesTags: ["reports"],
            }),
            studentExternalReportReportsStudentExternalStudentIdGet: build.query<
                StudentExternalReportReportsStudentExternalStudentIdGetApiResponse,
                StudentExternalReportReportsStudentExternalStudentIdGetApiArg
            >({
                query: (queryArg) => ({ url: `/reports/student-external/${queryArg.studentId}` }),
                providesTags: ["reports"],
            }),
            schoolReportsSchoolGet: build.query<
                SchoolReportsSchoolGetApiResponse,
                SchoolReportsSchoolGetApiArg
            >({
                query: () => ({ url: `/reports/school` }),
                providesTags: ["reports"],
            }),
            allPublishedResourceAsPublicAllPublishedGet: build.query<
                AllPublishedResourceAsPublicAllPublishedGetApiResponse,
                AllPublishedResourceAsPublicAllPublishedGetApiArg
            >({
                query: () => ({ url: `/resource/as-public/all/published` }),
                providesTags: ["resource-as-public"],
            }),
            getPublishedResourceAsPublicPublishedIdGet: build.query<
                GetPublishedResourceAsPublicPublishedIdGetApiResponse,
                GetPublishedResourceAsPublicPublishedIdGetApiArg
            >({
                query: (queryArg) => ({ url: `/resource/as-public/published/${queryArg.id}` }),
                providesTags: ["resource-as-public"],
            }),
            registerInterestFormsAsPublicRegisterInterestPost: build.mutation<
                RegisterInterestFormsAsPublicRegisterInterestPostApiResponse,
                RegisterInterestFormsAsPublicRegisterInterestPostApiArg
            >({
                query: (queryArg) => ({
                    url: `/forms/as-public/register-interest`,
                    method: "POST",
                    body: queryArg.createPublicFormRegisterInterest,
                }),
                invalidatesTags: ["forms-as-public"],
            }),
            allPublishedSchoolInviteAsPublicIdGet: build.query<
                AllPublishedSchoolInviteAsPublicIdGetApiResponse,
                AllPublishedSchoolInviteAsPublicIdGetApiArg
            >({
                query: (queryArg) => ({
                    url: `/school-invite/as-public/${queryArg.id}`,
                    params: { invite_hash: queryArg.inviteHash },
                }),
                providesTags: ["school-invite-as-public"],
            }),
            setupSchoolSchoolInviteAsPublicSchoolIdPost: build.mutation<
                SetupSchoolSchoolInviteAsPublicSchoolIdPostApiResponse,
                SetupSchoolSchoolInviteAsPublicSchoolIdPostApiArg
            >({
                query: (queryArg) => ({
                    url: `/school-invite/as-public/${queryArg.schoolId}`,
                    method: "POST",
                    body: queryArg.importSchoolModel,
                    params: { invite_hash: queryArg.inviteHash },
                }),
                invalidatesTags: ["school-invite-as-public"],
            }),
            getAllPaginatedResourceAsPlusStaffAllGet: build.query<
                GetAllPaginatedResourceAsPlusStaffAllGetApiResponse,
                GetAllPaginatedResourceAsPlusStaffAllGetApiArg
            >({
                query: (queryArg) => ({
                    url: `/resource/as-plus-staff/all`,
                    params: { page: queryArg.page, size: queryArg.size },
                }),
                providesTags: ["resource-as-plus-staff"],
            }),
            getResourceAsPlusStaffIdGet: build.query<
                GetResourceAsPlusStaffIdGetApiResponse,
                GetResourceAsPlusStaffIdGetApiArg
            >({
                query: (queryArg) => ({ url: `/resource/as-plus-staff/${queryArg.id}` }),
                providesTags: ["resource-as-plus-staff"],
            }),
            updateResourceAsPlusStaffIdPut: build.mutation<
                UpdateResourceAsPlusStaffIdPutApiResponse,
                UpdateResourceAsPlusStaffIdPutApiArg
            >({
                query: (queryArg) => ({
                    url: `/resource/as-plus-staff/${queryArg.id}`,
                    method: "PUT",
                    body: queryArg.resourceUpdateModel,
                }),
                invalidatesTags: ["resource-as-plus-staff"],
            }),
            deleteResourceAsPlusStaffIdDelete: build.mutation<
                DeleteResourceAsPlusStaffIdDeleteApiResponse,
                DeleteResourceAsPlusStaffIdDeleteApiArg
            >({
                query: (queryArg) => ({
                    url: `/resource/as-plus-staff/${queryArg.id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["resource-as-plus-staff"],
            }),
            createResourceAsPlusStaffPost: build.mutation<
                CreateResourceAsPlusStaffPostApiResponse,
                CreateResourceAsPlusStaffPostApiArg
            >({
                query: (queryArg) => ({
                    url: `/resource/as-plus-staff`,
                    method: "POST",
                    body: queryArg.resourceCreateModel,
                }),
                invalidatesTags: ["resource-as-plus-staff"],
            }),
            getAllPaginatedUserAsPlusStaffAllGet: build.query<
                GetAllPaginatedUserAsPlusStaffAllGetApiResponse,
                GetAllPaginatedUserAsPlusStaffAllGetApiArg
            >({
                query: (queryArg) => ({
                    url: `/user/as-plus-staff/all`,
                    params: { page: queryArg.page, size: queryArg.size },
                }),
                providesTags: ["user-as-plus-staff"],
            }),
            getUserAsPlusStaffIdGet: build.query<
                GetUserAsPlusStaffIdGetApiResponse,
                GetUserAsPlusStaffIdGetApiArg
            >({
                query: (queryArg) => ({ url: `/user/as-plus-staff/${queryArg.id}` }),
                providesTags: ["user-as-plus-staff"],
            }),
            updateUserAsPlusStaffIdPut: build.mutation<
                UpdateUserAsPlusStaffIdPutApiResponse,
                UpdateUserAsPlusStaffIdPutApiArg
            >({
                query: (queryArg) => ({
                    url: `/user/as-plus-staff/${queryArg.id}`,
                    method: "PUT",
                    body: queryArg.plusStaffUpdateUserModel,
                }),
                invalidatesTags: ["user-as-plus-staff"],
            }),
            deleteUserAsPlusStaffIdDelete: build.mutation<
                DeleteUserAsPlusStaffIdDeleteApiResponse,
                DeleteUserAsPlusStaffIdDeleteApiArg
            >({
                query: (queryArg) => ({
                    url: `/user/as-plus-staff/${queryArg.id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["user-as-plus-staff"],
            }),
            getAllPaginatedSchoolAsPlusStaffAllGet: build.query<
                GetAllPaginatedSchoolAsPlusStaffAllGetApiResponse,
                GetAllPaginatedSchoolAsPlusStaffAllGetApiArg
            >({
                query: (queryArg) => ({
                    url: `/school/as-plus-staff/all`,
                    params: { page: queryArg.page, size: queryArg.size },
                }),
                providesTags: ["school-as-plus-staff"],
            }),
            getSchoolAsPlusStaffIdGet: build.query<
                GetSchoolAsPlusStaffIdGetApiResponse,
                GetSchoolAsPlusStaffIdGetApiArg
            >({
                query: (queryArg) => ({ url: `/school/as-plus-staff/${queryArg.id}` }),
                providesTags: ["school-as-plus-staff"],
            }),
            updateSchoolAsPlusStaffIdPut: build.mutation<
                UpdateSchoolAsPlusStaffIdPutApiResponse,
                UpdateSchoolAsPlusStaffIdPutApiArg
            >({
                query: (queryArg) => ({
                    url: `/school/as-plus-staff/${queryArg.id}`,
                    method: "PUT",
                    body: queryArg.schoolUpdateModel,
                }),
                invalidatesTags: ["school-as-plus-staff"],
            }),
            deleteSchoolAsPlusStaffIdDelete: build.mutation<
                DeleteSchoolAsPlusStaffIdDeleteApiResponse,
                DeleteSchoolAsPlusStaffIdDeleteApiArg
            >({
                query: (queryArg) => ({
                    url: `/school/as-plus-staff/${queryArg.id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["school-as-plus-staff"],
            }),
            createSchoolAsPlusStaffPost: build.mutation<
                CreateSchoolAsPlusStaffPostApiResponse,
                CreateSchoolAsPlusStaffPostApiArg
            >({
                query: (queryArg) => ({
                    url: `/school/as-plus-staff`,
                    method: "POST",
                    body: queryArg.schoolCreateModel,
                }),
                invalidatesTags: ["school-as-plus-staff"],
            }),
            sendInviteSchoolAsPlusStaffSchoolIdPost: build.mutation<
                SendInviteSchoolAsPlusStaffSchoolIdPostApiResponse,
                SendInviteSchoolAsPlusStaffSchoolIdPostApiArg
            >({
                query: (queryArg) => ({
                    url: `/school/as-plus-staff/${queryArg.schoolId}`,
                    method: "POST",
                }),
                invalidatesTags: ["school-as-plus-staff"],
            }),
            resendTeacherInviteMiscAsPlusStaffResendTeacherInviteUserIdPost: build.mutation<
                ResendTeacherInviteMiscAsPlusStaffResendTeacherInviteUserIdPostApiResponse,
                ResendTeacherInviteMiscAsPlusStaffResendTeacherInviteUserIdPostApiArg
            >({
                query: (queryArg) => ({
                    url: `/misc/as-plus-staff/resend-teacher-invite/${queryArg.userId}`,
                    method: "POST",
                }),
                invalidatesTags: ["misc-as-plus-staff"],
            }),
            resendTeacherInviteMiscAsPlusStaffResendUserInviteUserIdPost: build.mutation<
                ResendTeacherInviteMiscAsPlusStaffResendUserInviteUserIdPostApiResponse,
                ResendTeacherInviteMiscAsPlusStaffResendUserInviteUserIdPostApiArg
            >({
                query: (queryArg) => ({
                    url: `/misc/as-plus-staff/resend-user-invite/${queryArg.userId}`,
                    method: "POST",
                }),
                invalidatesTags: ["misc-as-plus-staff"],
            }),
            fixScoresMiscAsPlusStaffFixScoresPost: build.mutation<
                FixScoresMiscAsPlusStaffFixScoresPostApiResponse,
                FixScoresMiscAsPlusStaffFixScoresPostApiArg
            >({
                query: () => ({ url: `/misc/as-plus-staff/fix-scores`, method: "POST" }),
                invalidatesTags: ["misc-as-plus-staff"],
            }),
            getAllPaginatedUserAsSchoolManagementAllGet: build.query<
                GetAllPaginatedUserAsSchoolManagementAllGetApiResponse,
                GetAllPaginatedUserAsSchoolManagementAllGetApiArg
            >({
                query: (queryArg) => ({
                    url: `/user/as-school-management/all`,
                    params: { page: queryArg.page, size: queryArg.size },
                }),
                providesTags: ["user-as-school-management"],
            }),
            getUserAsSchoolManagementIdGet: build.query<
                GetUserAsSchoolManagementIdGetApiResponse,
                GetUserAsSchoolManagementIdGetApiArg
            >({
                query: (queryArg) => ({ url: `/user/as-school-management/${queryArg.id}` }),
                providesTags: ["user-as-school-management"],
            }),
            deleteUserAsSchoolManagementIdDelete: build.mutation<
                DeleteUserAsSchoolManagementIdDeleteApiResponse,
                DeleteUserAsSchoolManagementIdDeleteApiArg
            >({
                query: (queryArg) => ({
                    url: `/user/as-school-management/${queryArg.id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["user-as-school-management"],
            }),
            createUserAsSchoolManagementPost: build.mutation<
                CreateUserAsSchoolManagementPostApiResponse,
                CreateUserAsSchoolManagementPostApiArg
            >({
                query: (queryArg) => ({
                    url: `/user/as-school-management`,
                    method: "POST",
                    body: queryArg.userCreateModel,
                }),
                invalidatesTags: ["user-as-school-management"],
            }),
            getAllPaginatedClassAsSchoolManagementAllGet: build.query<
                GetAllPaginatedClassAsSchoolManagementAllGetApiResponse,
                GetAllPaginatedClassAsSchoolManagementAllGetApiArg
            >({
                query: (queryArg) => ({
                    url: `/class/as-school-management/all`,
                    params: { page: queryArg.page, size: queryArg.size },
                }),
                providesTags: ["class-as-school-management"],
            }),
            possibleStudentsToAddClassAsSchoolManagementPossibleStudentsForClassGet: build.query<
                PossibleStudentsToAddClassAsSchoolManagementPossibleStudentsForClassGetApiResponse,
                PossibleStudentsToAddClassAsSchoolManagementPossibleStudentsForClassGetApiArg
            >({
                query: () => ({ url: `/class/as-school-management/possible-students-for-class` }),
                providesTags: ["class-as-school-management"],
            }),
            possibleTeachersToAddClassAsSchoolManagementPossibleTeachersForClassGet: build.query<
                PossibleTeachersToAddClassAsSchoolManagementPossibleTeachersForClassGetApiResponse,
                PossibleTeachersToAddClassAsSchoolManagementPossibleTeachersForClassGetApiArg
            >({
                query: () => ({ url: `/class/as-school-management/possible-teachers-for-class` }),
                providesTags: ["class-as-school-management"],
            }),
            getClassAsSchoolManagementIdGet: build.query<
                GetClassAsSchoolManagementIdGetApiResponse,
                GetClassAsSchoolManagementIdGetApiArg
            >({
                query: (queryArg) => ({ url: `/class/as-school-management/${queryArg.id}` }),
                providesTags: ["class-as-school-management"],
            }),
            updateClassAsSchoolManagementIdPut: build.mutation<
                UpdateClassAsSchoolManagementIdPutApiResponse,
                UpdateClassAsSchoolManagementIdPutApiArg
            >({
                query: (queryArg) => ({
                    url: `/class/as-school-management/${queryArg.id}`,
                    method: "PUT",
                    body: queryArg.classUpdateModel,
                }),
                invalidatesTags: ["class-as-school-management"],
            }),
            deleteClassAsSchoolManagementIdDelete: build.mutation<
                DeleteClassAsSchoolManagementIdDeleteApiResponse,
                DeleteClassAsSchoolManagementIdDeleteApiArg
            >({
                query: (queryArg) => ({
                    url: `/class/as-school-management/${queryArg.id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["class-as-school-management"],
            }),
            createClassAsSchoolManagementPost: build.mutation<
                CreateClassAsSchoolManagementPostApiResponse,
                CreateClassAsSchoolManagementPostApiArg
            >({
                query: (queryArg) => ({
                    url: `/class/as-school-management`,
                    method: "POST",
                    body: queryArg.classCreateModel,
                }),
                invalidatesTags: ["class-as-school-management"],
            }),
            getAllPaginatedStudentAsSchoolManagementAllGet: build.query<
                GetAllPaginatedStudentAsSchoolManagementAllGetApiResponse,
                GetAllPaginatedStudentAsSchoolManagementAllGetApiArg
            >({
                query: (queryArg) => ({
                    url: `/student/as-school-management/all`,
                    params: { page: queryArg.page, size: queryArg.size },
                }),
                providesTags: ["student-as-school-management"],
            }),
            getStudentAsSchoolManagementIdGet: build.query<
                GetStudentAsSchoolManagementIdGetApiResponse,
                GetStudentAsSchoolManagementIdGetApiArg
            >({
                query: (queryArg) => ({ url: `/student/as-school-management/${queryArg.id}` }),
                providesTags: ["student-as-school-management"],
            }),
            updateStudentAsSchoolManagementIdPut: build.mutation<
                UpdateStudentAsSchoolManagementIdPutApiResponse,
                UpdateStudentAsSchoolManagementIdPutApiArg
            >({
                query: (queryArg) => ({
                    url: `/student/as-school-management/${queryArg.id}`,
                    method: "PUT",
                    body: queryArg.schoolManagementStudentUpdateModel,
                }),
                invalidatesTags: ["student-as-school-management"],
            }),
            deleteStudentAsSchoolManagementIdDelete: build.mutation<
                DeleteStudentAsSchoolManagementIdDeleteApiResponse,
                DeleteStudentAsSchoolManagementIdDeleteApiArg
            >({
                query: (queryArg) => ({
                    url: `/student/as-school-management/${queryArg.id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["student-as-school-management"],
            }),
            getEditStudentAsSchoolManagementEditIdGet: build.query<
                GetEditStudentAsSchoolManagementEditIdGetApiResponse,
                GetEditStudentAsSchoolManagementEditIdGetApiArg
            >({
                query: (queryArg) => ({ url: `/student/as-school-management/edit/${queryArg.id}` }),
                providesTags: ["student-as-school-management"],
            }),
            createStudentAsSchoolManagementPost: build.mutation<
                CreateStudentAsSchoolManagementPostApiResponse,
                CreateStudentAsSchoolManagementPostApiArg
            >({
                query: (queryArg) => ({
                    url: `/student/as-school-management`,
                    method: "POST",
                    body: queryArg.schoolManagementStudentCreateModel,
                }),
                invalidatesTags: ["student-as-school-management"],
            }),
            getAllPaginatedTeacherAsSchoolManagementAllGet: build.query<
                GetAllPaginatedTeacherAsSchoolManagementAllGetApiResponse,
                GetAllPaginatedTeacherAsSchoolManagementAllGetApiArg
            >({
                query: (queryArg) => ({
                    url: `/teacher/as-school-management/all`,
                    params: { page: queryArg.page, size: queryArg.size },
                }),
                providesTags: ["teacher-as-school-management"],
            }),
            getTeacherAsSchoolManagementIdGet: build.query<
                GetTeacherAsSchoolManagementIdGetApiResponse,
                GetTeacherAsSchoolManagementIdGetApiArg
            >({
                query: (queryArg) => ({ url: `/teacher/as-school-management/${queryArg.id}` }),
                providesTags: ["teacher-as-school-management"],
            }),
            updateTeacherAsSchoolManagementIdPut: build.mutation<
                UpdateTeacherAsSchoolManagementIdPutApiResponse,
                UpdateTeacherAsSchoolManagementIdPutApiArg
            >({
                query: (queryArg) => ({
                    url: `/teacher/as-school-management/${queryArg.id}`,
                    method: "PUT",
                    body: queryArg.schoolManagementTeacherUpdateModel,
                }),
                invalidatesTags: ["teacher-as-school-management"],
            }),
            deleteTeacherAsSchoolManagementIdDelete: build.mutation<
                DeleteTeacherAsSchoolManagementIdDeleteApiResponse,
                DeleteTeacherAsSchoolManagementIdDeleteApiArg
            >({
                query: (queryArg) => ({
                    url: `/teacher/as-school-management/${queryArg.id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["teacher-as-school-management"],
            }),
            createTeacherAsSchoolManagementPost: build.mutation<
                CreateTeacherAsSchoolManagementPostApiResponse,
                CreateTeacherAsSchoolManagementPostApiArg
            >({
                query: (queryArg) => ({
                    url: `/teacher/as-school-management`,
                    method: "POST",
                    body: queryArg.schoolManagementTeacherCreateModel,
                }),
                invalidatesTags: ["teacher-as-school-management"],
            }),
            updateSchoolSettingsAsSchoolManagementGet: build.query<
                UpdateSchoolSettingsAsSchoolManagementGetApiResponse,
                UpdateSchoolSettingsAsSchoolManagementGetApiArg
            >({
                query: () => ({ url: `/school-settings/as-school-management` }),
                providesTags: ["school-settings-as-school-management"],
            }),
            updateSchoolSettingsAsSchoolManagementPut: build.mutation<
                UpdateSchoolSettingsAsSchoolManagementPutApiResponse,
                UpdateSchoolSettingsAsSchoolManagementPutApiArg
            >({
                query: (queryArg) => ({
                    url: `/school-settings/as-school-management`,
                    method: "PUT",
                    body: queryArg.schoolUpdateModel,
                }),
                invalidatesTags: ["school-settings-as-school-management"],
            }),
            statsJournalAsStudentStatsGet: build.query<
                StatsJournalAsStudentStatsGetApiResponse,
                StatsJournalAsStudentStatsGetApiArg
            >({
                query: () => ({ url: `/journal/as-student/stats` }),
                providesTags: ["journal-as-student"],
            }),
            createJournalAsStudentPost: build.mutation<
                CreateJournalAsStudentPostApiResponse,
                CreateJournalAsStudentPostApiArg
            >({
                query: (queryArg) => ({
                    url: `/journal/as-student`,
                    method: "POST",
                    body: queryArg.createJournalEntrySchema,
                }),
                invalidatesTags: ["journal-as-student"],
            }),
            createQuestionnaireAsStudentPost: build.mutation<
                CreateQuestionnaireAsStudentPostApiResponse,
                CreateQuestionnaireAsStudentPostApiArg
            >({
                query: (queryArg) => ({
                    url: `/questionnaire/as-student`,
                    method: "POST",
                    body: queryArg.createStudentQuestionnare,
                    params: { questionnaireType: queryArg.questionnaireType },
                }),
                invalidatesTags: ["questionnaire-as-student"],
            }),
            updateAvatarAsStudentPut: build.mutation<
                UpdateAvatarAsStudentPutApiResponse,
                UpdateAvatarAsStudentPutApiArg
            >({
                query: (queryArg) => ({
                    url: `/avatar/as-student`,
                    method: "PUT",
                    body: queryArg.avatarJson,
                }),
                invalidatesTags: ["avatar-as-student"],
            }),
            allBadgesAsStudentGet: build.query<
                AllBadgesAsStudentGetApiResponse,
                AllBadgesAsStudentGetApiArg
            >({
                query: () => ({ url: `/badges/as-student` }),
                providesTags: ["badges-as-student"],
            }),
            createBadgesAsStudentPost: build.mutation<
                CreateBadgesAsStudentPostApiResponse,
                CreateBadgesAsStudentPostApiArg
            >({
                query: (queryArg) => ({
                    url: `/badges/as-student`,
                    method: "POST",
                    body: queryArg.body,
                }),
                invalidatesTags: ["badges-as-student"],
            }),
            readAtBadgesAsStudentIdPut: build.mutation<
                ReadAtBadgesAsStudentIdPutApiResponse,
                ReadAtBadgesAsStudentIdPutApiArg
            >({
                query: (queryArg) => ({ url: `/badges/as-student/${queryArg.id}`, method: "PUT" }),
                invalidatesTags: ["badges-as-student"],
            }),
            unlockedResourcesUnlockedResourcesAsStudentGet: build.query<
                UnlockedResourcesUnlockedResourcesAsStudentGetApiResponse,
                UnlockedResourcesUnlockedResourcesAsStudentGetApiArg
            >({
                query: () => ({ url: `/unlocked-resources/as-student` }),
                providesTags: ["unlocked-resources-as-student"],
            }),
            getAllPaginatedStudentAsTeacherAllGet: build.query<
                GetAllPaginatedStudentAsTeacherAllGetApiResponse,
                GetAllPaginatedStudentAsTeacherAllGetApiArg
            >({
                query: (queryArg) => ({
                    url: `/student/as-teacher/all`,
                    params: { page: queryArg.page, size: queryArg.size },
                }),
                providesTags: ["student-as-teacher"],
            }),
            getStudentAsTeacherIdGet: build.query<
                GetStudentAsTeacherIdGetApiResponse,
                GetStudentAsTeacherIdGetApiArg
            >({
                query: (queryArg) => ({ url: `/student/as-teacher/${queryArg.id}` }),
                providesTags: ["student-as-teacher"],
            }),
            getAllPaginatedClassAsTeacherAllGet: build.query<
                GetAllPaginatedClassAsTeacherAllGetApiResponse,
                GetAllPaginatedClassAsTeacherAllGetApiArg
            >({
                query: (queryArg) => ({
                    url: `/class/as-teacher/all`,
                    params: { page: queryArg.page, size: queryArg.size },
                }),
                providesTags: ["class-as-teacher"],
            }),
            getClassAsTeacherIdGet: build.query<
                GetClassAsTeacherIdGetApiResponse,
                GetClassAsTeacherIdGetApiArg
            >({
                query: (queryArg) => ({ url: `/class/as-teacher/${queryArg.id}` }),
                providesTags: ["class-as-teacher"],
            }),
        }),
        overrideExisting: false,
    })
export { injectedRtkApi as publicApi }
export type GetMeSettingsGetApiResponse = /** status 200 Successful Response */ MeResponseSchema
export type GetMeSettingsGetApiArg = void
export type UpdateMeSettingsPutApiResponse = /** status 200 Successful Response */ any
export type UpdateMeSettingsPutApiArg = {
    meSchema: MeSchema
}
export type CanSetupAccountSetupAccountSetupAccountUserIdGetApiResponse =
    /** status 200 Successful Response */ any
export type CanSetupAccountSetupAccountSetupAccountUserIdGetApiArg = {
    userId: string
    loginHash: string
}
export type SetupAccountSetupAccountSetupAccountUserIdPutApiResponse =
    /** status 200 Successful Response */ any
export type SetupAccountSetupAccountSetupAccountUserIdPutApiArg = {
    userId: string
    loginHash: string
    setupAccountModel: SetupAccountModel
}
export type PostCommentAiPostApiResponse =
    /** status 200 Successful Response */ CommentAiAnalysisResponse
export type PostCommentAiPostApiArg = {
    commentAiAnalysis: CommentAiAnalysis
}
export type GetUserMetaGetApiResponse = /** status 200 Successful Response */ UserMeta
export type GetUserMetaGetApiArg = void
export type SparklineStatsSparklineGetApiResponse =
    /** status 200 Successful Response */ SparklineStat[]
export type SparklineStatsSparklineGetApiArg = {
    filterClassId?: string
    filterStudentId?: string
}
export type StatsStatsPostApiResponse = /** status 200 Successful Response */ StatsResponseSchema
export type StatsStatsPostApiArg = {
    filterBySendco?: boolean
    filterByPremium?: boolean
    bodyStatsStatsPost: BodyStatsStatsPost
}
export type ClassReportReportsClassClassIdGetApiResponse =
    /** status 200 Successful Response */ ClassReportStat
export type ClassReportReportsClassClassIdGetApiArg = {
    classId: string
}
export type StudentInternalReportReportsStudentInternalStudentIdGetApiResponse =
    /** status 200 Successful Response */ any
export type StudentInternalReportReportsStudentInternalStudentIdGetApiArg = {
    studentId: string
}
export type StudentExternalReportReportsStudentExternalStudentIdGetApiResponse =
    /** status 200 Successful Response */ any
export type StudentExternalReportReportsStudentExternalStudentIdGetApiArg = {
    studentId: string
}
export type SchoolReportsSchoolGetApiResponse =
    /** status 200 Successful Response */ SchoolReportStat
export type SchoolReportsSchoolGetApiArg = void
export type AllPublishedResourceAsPublicAllPublishedGetApiResponse =
    /** status 200 Successful Response */ ResourceResponsePublicModel[]
export type AllPublishedResourceAsPublicAllPublishedGetApiArg = void
export type GetPublishedResourceAsPublicPublishedIdGetApiResponse =
    /** status 200 Successful Response */ ResourceResponsePublicModel
export type GetPublishedResourceAsPublicPublishedIdGetApiArg = {
    id: string
}
export type RegisterInterestFormsAsPublicRegisterInterestPostApiResponse =
    /** status 200 Successful Response */ any
export type RegisterInterestFormsAsPublicRegisterInterestPostApiArg = {
    createPublicFormRegisterInterest: CreatePublicFormRegisterInterest
}
export type AllPublishedSchoolInviteAsPublicIdGetApiResponse =
    /** status 200 Successful Response */ PublicSchoolInviteResponseModel
export type AllPublishedSchoolInviteAsPublicIdGetApiArg = {
    id: string
    inviteHash: string
}
export type SetupSchoolSchoolInviteAsPublicSchoolIdPostApiResponse =
    /** status 200 Successful Response */ any
export type SetupSchoolSchoolInviteAsPublicSchoolIdPostApiArg = {
    schoolId: string
    inviteHash: string
    importSchoolModel: ImportSchoolModel
}
export type GetAllPaginatedResourceAsPlusStaffAllGetApiResponse =
    /** status 200 Successful Response */ PageResourceResponseModel
export type GetAllPaginatedResourceAsPlusStaffAllGetApiArg = {
    page?: number
    size?: number
}
export type GetResourceAsPlusStaffIdGetApiResponse =
    /** status 200 Successful Response */ ResourceResponseModel
export type GetResourceAsPlusStaffIdGetApiArg = {
    id: string
}
export type UpdateResourceAsPlusStaffIdPutApiResponse = /** status 200 Successful Response */ any
export type UpdateResourceAsPlusStaffIdPutApiArg = {
    id: string
    resourceUpdateModel: ResourceUpdateModel
}
export type DeleteResourceAsPlusStaffIdDeleteApiResponse = /** status 200 Successful Response */ any
export type DeleteResourceAsPlusStaffIdDeleteApiArg = {
    id: string
}
export type CreateResourceAsPlusStaffPostApiResponse = /** status 200 Successful Response */ any
export type CreateResourceAsPlusStaffPostApiArg = {
    resourceCreateModel: ResourceCreateModel
}
export type GetAllPaginatedUserAsPlusStaffAllGetApiResponse =
    /** status 200 Successful Response */ PageUserResponseModel
export type GetAllPaginatedUserAsPlusStaffAllGetApiArg = {
    page?: number
    size?: number
}
export type GetUserAsPlusStaffIdGetApiResponse =
    /** status 200 Successful Response */ UserResponseModel
export type GetUserAsPlusStaffIdGetApiArg = {
    id: string
}
export type UpdateUserAsPlusStaffIdPutApiResponse = /** status 200 Successful Response */ any
export type UpdateUserAsPlusStaffIdPutApiArg = {
    id: string
    plusStaffUpdateUserModel: PlusStaffUpdateUserModel
}
export type DeleteUserAsPlusStaffIdDeleteApiResponse = /** status 200 Successful Response */ any
export type DeleteUserAsPlusStaffIdDeleteApiArg = {
    id: string
}
export type GetAllPaginatedSchoolAsPlusStaffAllGetApiResponse =
    /** status 200 Successful Response */ PageSchoolResponseModel
export type GetAllPaginatedSchoolAsPlusStaffAllGetApiArg = {
    page?: number
    size?: number
}
export type GetSchoolAsPlusStaffIdGetApiResponse =
    /** status 200 Successful Response */ SchoolResponseModel
export type GetSchoolAsPlusStaffIdGetApiArg = {
    id: string
}
export type UpdateSchoolAsPlusStaffIdPutApiResponse = /** status 200 Successful Response */ any
export type UpdateSchoolAsPlusStaffIdPutApiArg = {
    id: string
    schoolUpdateModel: SchoolUpdateModel
}
export type DeleteSchoolAsPlusStaffIdDeleteApiResponse = /** status 200 Successful Response */ any
export type DeleteSchoolAsPlusStaffIdDeleteApiArg = {
    id: string
}
export type CreateSchoolAsPlusStaffPostApiResponse = /** status 200 Successful Response */ any
export type CreateSchoolAsPlusStaffPostApiArg = {
    schoolCreateModel: SchoolCreateModel
}
export type SendInviteSchoolAsPlusStaffSchoolIdPostApiResponse =
    /** status 200 Successful Response */ any
export type SendInviteSchoolAsPlusStaffSchoolIdPostApiArg = {
    schoolId: string
}
export type ResendTeacherInviteMiscAsPlusStaffResendTeacherInviteUserIdPostApiResponse =
    /** status 200 Successful Response */ any
export type ResendTeacherInviteMiscAsPlusStaffResendTeacherInviteUserIdPostApiArg = {
    userId: string
}
export type ResendTeacherInviteMiscAsPlusStaffResendUserInviteUserIdPostApiResponse =
    /** status 200 Successful Response */ any
export type ResendTeacherInviteMiscAsPlusStaffResendUserInviteUserIdPostApiArg = {
    userId: string
}
export type FixScoresMiscAsPlusStaffFixScoresPostApiResponse =
    /** status 200 Successful Response */ any
export type FixScoresMiscAsPlusStaffFixScoresPostApiArg = void
export type GetAllPaginatedUserAsSchoolManagementAllGetApiResponse =
    /** status 200 Successful Response */ PageUserResponseModel
export type GetAllPaginatedUserAsSchoolManagementAllGetApiArg = {
    page?: number
    size?: number
}
export type GetUserAsSchoolManagementIdGetApiResponse =
    /** status 200 Successful Response */ UserResponseModel
export type GetUserAsSchoolManagementIdGetApiArg = {
    id: string
}
export type DeleteUserAsSchoolManagementIdDeleteApiResponse =
    /** status 200 Successful Response */ any
export type DeleteUserAsSchoolManagementIdDeleteApiArg = {
    id: string
}
export type CreateUserAsSchoolManagementPostApiResponse = /** status 200 Successful Response */ any
export type CreateUserAsSchoolManagementPostApiArg = {
    userCreateModel: UserCreateModel
}
export type GetAllPaginatedClassAsSchoolManagementAllGetApiResponse =
    /** status 200 Successful Response */ PageClassResponseModel
export type GetAllPaginatedClassAsSchoolManagementAllGetApiArg = {
    page?: number
    size?: number
}
export type PossibleStudentsToAddClassAsSchoolManagementPossibleStudentsForClassGetApiResponse =
    /** status 200 Successful Response */ ClassAvailableUserModel[]
export type PossibleStudentsToAddClassAsSchoolManagementPossibleStudentsForClassGetApiArg = void
export type PossibleTeachersToAddClassAsSchoolManagementPossibleTeachersForClassGetApiResponse =
    /** status 200 Successful Response */ ClassAvailableUserModel[]
export type PossibleTeachersToAddClassAsSchoolManagementPossibleTeachersForClassGetApiArg = void
export type GetClassAsSchoolManagementIdGetApiResponse = /** status 200 Successful Response */ any
export type GetClassAsSchoolManagementIdGetApiArg = {
    id: string
}
export type UpdateClassAsSchoolManagementIdPutApiResponse =
    /** status 200 Successful Response */ any
export type UpdateClassAsSchoolManagementIdPutApiArg = {
    id: string
    classUpdateModel: ClassUpdateModel
}
export type DeleteClassAsSchoolManagementIdDeleteApiResponse =
    /** status 200 Successful Response */ any
export type DeleteClassAsSchoolManagementIdDeleteApiArg = {
    id: string
}
export type CreateClassAsSchoolManagementPostApiResponse = /** status 200 Successful Response */ any
export type CreateClassAsSchoolManagementPostApiArg = {
    classCreateModel: ClassCreateModel
}
export type GetAllPaginatedStudentAsSchoolManagementAllGetApiResponse =
    /** status 200 Successful Response */ PageUserResponseModel
export type GetAllPaginatedStudentAsSchoolManagementAllGetApiArg = {
    page?: number
    size?: number
}
export type GetStudentAsSchoolManagementIdGetApiResponse =
    /** status 200 Successful Response */ UserResponseModel
export type GetStudentAsSchoolManagementIdGetApiArg = {
    id: string
}
export type UpdateStudentAsSchoolManagementIdPutApiResponse =
    /** status 200 Successful Response */ any
export type UpdateStudentAsSchoolManagementIdPutApiArg = {
    id: string
    schoolManagementStudentUpdateModel: SchoolManagementStudentUpdateModel
}
export type DeleteStudentAsSchoolManagementIdDeleteApiResponse =
    /** status 200 Successful Response */ any
export type DeleteStudentAsSchoolManagementIdDeleteApiArg = {
    id: string
}
export type GetEditStudentAsSchoolManagementEditIdGetApiResponse =
    /** status 200 Successful Response */ SchoolManagementStudentUpdateModel
export type GetEditStudentAsSchoolManagementEditIdGetApiArg = {
    id: string
}
export type CreateStudentAsSchoolManagementPostApiResponse =
    /** status 200 Successful Response */ any
export type CreateStudentAsSchoolManagementPostApiArg = {
    schoolManagementStudentCreateModel: SchoolManagementStudentCreateModel
}
export type GetAllPaginatedTeacherAsSchoolManagementAllGetApiResponse =
    /** status 200 Successful Response */ PageUserResponseModel
export type GetAllPaginatedTeacherAsSchoolManagementAllGetApiArg = {
    page?: number
    size?: number
}
export type GetTeacherAsSchoolManagementIdGetApiResponse =
    /** status 200 Successful Response */ SchoolManagementTeacherUpdateModelResponse
export type GetTeacherAsSchoolManagementIdGetApiArg = {
    id: string
}
export type UpdateTeacherAsSchoolManagementIdPutApiResponse =
    /** status 200 Successful Response */ any
export type UpdateTeacherAsSchoolManagementIdPutApiArg = {
    id: string
    schoolManagementTeacherUpdateModel: SchoolManagementTeacherUpdateModel
}
export type DeleteTeacherAsSchoolManagementIdDeleteApiResponse =
    /** status 200 Successful Response */ any
export type DeleteTeacherAsSchoolManagementIdDeleteApiArg = {
    id: string
}
export type CreateTeacherAsSchoolManagementPostApiResponse =
    /** status 200 Successful Response */ any
export type CreateTeacherAsSchoolManagementPostApiArg = {
    schoolManagementTeacherCreateModel: SchoolManagementTeacherCreateModel
}
export type UpdateSchoolSettingsAsSchoolManagementGetApiResponse =
    /** status 200 Successful Response */ SchoolManagementSchoolResponseModel
export type UpdateSchoolSettingsAsSchoolManagementGetApiArg = void
export type UpdateSchoolSettingsAsSchoolManagementPutApiResponse =
    /** status 200 Successful Response */ any
export type UpdateSchoolSettingsAsSchoolManagementPutApiArg = {
    schoolUpdateModel: SchoolUpdateModel
}
export type StatsJournalAsStudentStatsGetApiResponse =
    /** status 200 Successful Response */ JournalStatsForStudent
export type StatsJournalAsStudentStatsGetApiArg = void
export type CreateJournalAsStudentPostApiResponse = /** status 200 Successful Response */ any
export type CreateJournalAsStudentPostApiArg = {
    createJournalEntrySchema: CreateJournalEntrySchema
}
export type CreateQuestionnaireAsStudentPostApiResponse = /** status 200 Successful Response */ any
export type CreateQuestionnaireAsStudentPostApiArg = {
    questionnaireType: QuestionnaireTypeEnum
    createStudentQuestionnare: CreateStudentQuestionnare
}
export type UpdateAvatarAsStudentPutApiResponse = /** status 200 Successful Response */ any
export type UpdateAvatarAsStudentPutApiArg = {
    avatarJson: AvatarJson
}
export type AllBadgesAsStudentGetApiResponse = /** status 200 Successful Response */ BadgeSchema[]
export type AllBadgesAsStudentGetApiArg = void
export type CreateBadgesAsStudentPostApiResponse =
    /** status 200 Successful Response */ BadgeSchema[]
export type CreateBadgesAsStudentPostApiArg = {
    body: BadgeSchema[]
}
export type ReadAtBadgesAsStudentIdPutApiResponse = /** status 200 Successful Response */ any
export type ReadAtBadgesAsStudentIdPutApiArg = {
    id: string
}
export type UnlockedResourcesUnlockedResourcesAsStudentGetApiResponse =
    /** status 200 Successful Response */ any
export type UnlockedResourcesUnlockedResourcesAsStudentGetApiArg = void
export type GetAllPaginatedStudentAsTeacherAllGetApiResponse =
    /** status 200 Successful Response */ PageUserResponseModel
export type GetAllPaginatedStudentAsTeacherAllGetApiArg = {
    page?: number
    size?: number
}
export type GetStudentAsTeacherIdGetApiResponse =
    /** status 200 Successful Response */ UserResponseModel
export type GetStudentAsTeacherIdGetApiArg = {
    id: string
}
export type GetAllPaginatedClassAsTeacherAllGetApiResponse =
    /** status 200 Successful Response */ PageClassResponseModel
export type GetAllPaginatedClassAsTeacherAllGetApiArg = {
    page?: number
    size?: number
}
export type GetClassAsTeacherIdGetApiResponse = /** status 200 Successful Response */ any
export type GetClassAsTeacherIdGetApiArg = {
    id: string
}
export type MeResponseSchema = {
    firstname: string
    lastname: string
    email: string
    profile_image_url?: string
}
export type ValidationError = {
    loc: (string | number)[]
    msg: string
    type: string
}
export type HttpValidationError = {
    detail?: ValidationError[]
}
export type MeSchema = {
    firstname: string
    lastname: string
    email: string
    password: string
    profile_image_url?: string
}
export type SetupAccountModel = {
    password?: string
}
export type CommentAiAnalysisResponse = {
    comment_ai_negativity_score: number
}
export type CommentAiAnalysis = {
    comment: string
}
export type UserMetaFieldTypeEnum =
    | "Avatar"
    | "Redirect"
    | "<class 'app.models.user_meta.UserMetaFieldTypeEnum.Config'>"
export type SvgItem = {
    svg: number
}
export type SvgAndColorItem = {
    svg: number
    color: string
}
export type AvatarJson = {
    background: SvgItem
    skin: SvgItem
    hair: SvgAndColorItem
    facialHair: SvgAndColorItem
    body: SvgAndColorItem
    eyes: SvgItem
    mouth: SvgItem
}
export type UserMetaFieldAvatar = {
    type?: UserMetaFieldTypeEnum
    value?: AvatarJson
}
export type UserMetaFieldRedirect = {
    type?: UserMetaFieldTypeEnum
    value?: string
}
export type UserMeta = {
    meta?: (UserMetaFieldAvatar | UserMetaFieldRedirect)[]
}
export type SparklineStat = {
    _id: string
    average_wellbeing_total_score?: number
    average_wellbeing_total_score_percent?: number
    average_wellbeing_total_score_possible?: number
}
export type YearGroupEnum =
    | "1"
    | "2"
    | "3"
    | "4"
    | "5"
    | "6"
    | "7"
    | "8"
    | "9"
    | "10"
    | "11"
    | "12"
    | "13"
export type StatClassResponseSchema = {
    _id: string
    name: string
    year_group: YearGroupEnum
}
export type StatStudentResponseSchema = {
    _id: string
    name: string
}
export type FlaggedCommentSchema = {
    _id: string
    student_id: string
    student_name: string
    comment: string
    has_requested_support: boolean
    comment_ai_score?: number
    created_at: string
}
export type TimestampStatClassResponseSchema = {
    _id: string
    name: string
}
export type AveragePhysicalWellbeingStats = {
    average_sleep?: number
    average_water?: number
    average_activity?: number
    average_fruit_and_veg?: number
    average_sugar?: number
    average_worn_out?: number
    average_total?: number
}
export type AverageEmotionalWellbeingStats = {
    average_happy?: number
    average_positive?: number
    average_grateful?: number
    average_sad?: number
    average_angry?: number
    average_worried?: number
    average_total?: number
}
export type StatResponseBaseSchema = {
    average_physical_wellbeing: AveragePhysicalWellbeingStats
    average_emotional_wellbeing: AverageEmotionalWellbeingStats
    average_physical_wellbeing_total_score?: number
    average_physical_wellbeing_total_score_percent?: number
    average_physical_wellbeing_total_score_possible?: number
    average_emotional_wellbeing_total_score?: number
    average_emotional_wellbeing_total_score_percent?: number
    average_emotional_wellbeing_total_score_possible?: number
    average_wellbeing_total_score?: number
    average_wellbeing_total_score_percent?: number
    average_wellbeing_total_score_possible?: number
    sum_stars?: number
    sum_read_resources?: number
    sum_reports_downloaded?: number
    sum_flags?: number
}
export type TimestampStatResponseSchema = {
    _id: string
    size?: number
    year_group: YearGroupEnum
    classes?: TimestampStatClassResponseSchema[]
    stats: StatResponseBaseSchema
}
export type CircumplexOfEmotionsStudentEntry = {
    _id: string
    student_name: string
    year_group: string
    class_name: string
    x: number
    y: number
}
export type StatsResponseSchema = {
    year_groups?: YearGroupEnum[]
    classes?: StatClassResponseSchema[]
    students?: StatStudentResponseSchema[]
    top_flagged_comments?: FlaggedCommentSchema[]
    stats: TimestampStatResponseSchema[]
    circumplex_of_emotions: CircumplexOfEmotionsStudentEntry[]
    count: number
}
export type TimePeriodEnum =
    | "Custom"
    | "Single Day"
    | "Single Month"
    | "Today"
    | "Yesterday"
    | "This Week"
    | "This Month"
    | "This Year"
    | "Last 7 Days"
    | "Last 30 Days"
    | "Last 90 Days"
export type TimePeriodIntervalEnum = "none" | "daily" | "monthly"
export type TimePeriod = {
    start_at: string
    end_at: string
    type: TimePeriodEnum
    interval: TimePeriodIntervalEnum
}
export type BodyStatsStatsPost = {
    time_period?: TimePeriod
    filter_year_groups?: YearGroupEnum[]
    filter_classes?: string[]
    filter_students?: string[]
}
export type ReportStudentCommentSchema = {
    _id: string
    student_id: string
    student_name: string
    comment: string
    has_requested_support: boolean
    comment_ai_score?: number
    created_at: string
}
export type ClassReportStatSeries = {
    name: string
    data: (number | number)[]
}
export type ClassReportStat = {
    _id: string
    name: string
    top_flagged_comments: ReportStudentCommentSchema[]
    average_physical_wellbeing_total_score?: number
    average_physical_wellbeing_total_score_percent?: number
    average_physical_wellbeing_total_score_possible?: number
    average_emotional_wellbeing_total_score?: number
    average_emotional_wellbeing_total_score_percent?: number
    average_emotional_wellbeing_total_score_possible?: number
    sum_stars?: number
    sum_badges?: number
    sum_read_resources?: number
    sum_reports_downloaded?: number
    sum_flags?: number
    series_wellbeing: ClassReportStatSeries[]
    series_time: string[]
}
export type SchoolReportYearStatSeries = {
    name: string
    data: (number | number)[]
}
export type SchoolReportYearStat = {
    _id: string
    name: string
    average_physical_wellbeing_total_score?: number
    average_physical_wellbeing_total_score_percent?: number
    average_physical_wellbeing_total_score_possible?: number
    average_emotional_wellbeing_total_score?: number
    average_emotional_wellbeing_total_score_percent?: number
    average_emotional_wellbeing_total_score_possible?: number
    sum_stars?: number
    sum_badges?: number
    sum_read_resources?: number
    sum_reports_downloaded?: number
    sum_flags?: number
    work_on: string
    keep_up: string
    series_wellbeing: SchoolReportYearStatSeries[]
}
export type SchoolReportStat = {
    name: string
    average_physical_wellbeing_total_score?: number
    average_physical_wellbeing_total_score_percent?: number
    average_physical_wellbeing_total_score_possible?: number
    average_emotional_wellbeing_total_score?: number
    average_emotional_wellbeing_total_score_percent?: number
    average_emotional_wellbeing_total_score_possible?: number
    sum_stars?: number
    sum_badges?: number
    sum_read_resources?: number
    sum_reports_downloaded?: number
    sum_flags?: number
    years: SchoolReportYearStat[]
    series_time: string[]
}
export type ResourceCategoryEnum = "Physical Wellbeing" | "Emotional Wellbeing"
export type ResourceResponsePublicModel = {
    _id?: string
    title: string
    topic: string
    category: ResourceCategoryEnum
    small_description: string
    video_vimeo_url?: string
    content?: any
    color?: string
    emoji?: string
}
export type CreatePublicFormRegisterInterest = {
    name: string
    email: string
}
export type SchoolSizeEnum =
    | "0 - 100"
    | "101 - 500"
    | "501 - 1000"
    | "1k - 5k"
    | "5k - 10k"
    | "10k or more"
export type SchoolTypeEnum = "Primary School" | "Secondary School" | "College"
export type PublicSchoolInviteResponseModel = {
    _id?: string
    name: string
    logo_url?: string
    motto: string
    address: string
    school_size: SchoolSizeEnum
    school_type: SchoolTypeEnum
    invited_at?: string
}
export type ImportSchoolStudentModel = {
    _id: string
    upn_id?: string
    email?: string
    firstname: string
    lastname: string
    is_sendco?: boolean
    is_premium?: boolean
}
export type ImportSchoolTeacherModel = {
    _id: string
    email?: string
    firstname: string
    lastname: string
    login_hash?: string
}
export type ImportSchoolClassModel = {
    _id: string
    name: string
    year_group: YearGroupEnum
    students: string[]
    teachers: string[]
}
export type ImportSchoolAnonymousClassModel = {
    _id: string
    name: string
    year_group: YearGroupEnum
    student_count: number
    teachers: string[]
}
export type ImportSchoolAllowedRoleEnum = "school_management" | "senco" | "premium" | "headteacher"
export type ImportSchoolUserModel = {
    _id: string
    email?: string
    firstname: string
    lastname: string
    role: ImportSchoolAllowedRoleEnum
    login_hash?: string
}
export type ImportSchoolModel = {
    _id?: string
    name: string
    logo_url?: string
    motto: string
    address: string
    school_size: SchoolSizeEnum
    school_type: SchoolTypeEnum
    invited_at?: string
    students: ImportSchoolStudentModel[]
    teachers: ImportSchoolTeacherModel[]
    classes: ImportSchoolClassModel[]
    anonymous_classes: ImportSchoolAnonymousClassModel[]
    users: ImportSchoolUserModel[]
}
export type ResourceResponseModel = {
    _id?: string
    title: string
    topic: string
    category: ResourceCategoryEnum
    small_description: string
    video_vimeo_url?: string
    content?: any
    color?: string
    emoji?: string
    created_at: string
    updated_at: string
    published_at?: string
    deleted_at?: string
}
export type PageResourceResponseModel = {
    items: ResourceResponseModel[]
    total: number
    page: number
    size: number
}
export type ResourceUpdateModel = {
    title: string
    topic: string
    category: ResourceCategoryEnum
    small_description: string
    video_vimeo_url?: string
    content?: any
    color?: string
    emoji?: string
    published_at?: string
}
export type ResourceCreateModel = {
    title: string
    topic: string
    category: ResourceCategoryEnum
    small_description: string
    video_vimeo_url?: string
    content?: any
    color?: string
    emoji?: string
    published_at?: string
}
export type RoleEnum =
    | "public"
    | "student"
    | "teacher"
    | "headteacher"
    | "school_management"
    | "senco"
    | "plus_staff"
    | "premium"
export type Role = {
    role: RoleEnum
}
export type BadgeReasonEnum =
    | "ResourceOpened"
    | "ResourceCompleted"
    | "ResourcesOpened"
    | "DailyWeekdayLogin"
    | "DailyWeekendLogin"
    | "CommentWritten"
    | "JournalCompleted"
    | "ClassChallengeFinishedWithImprovement"
    | "BadgeEasterEgg"
export type BadgeResourceOpened = {
    reason?: BadgeReasonEnum
    message?: string
    value?: number
}
export type BadgeResourceCompleted = {
    reason?: BadgeReasonEnum
    message?: string
    value?: number
}
export type BadgeResourcesOpened = {
    reason?: BadgeReasonEnum
    message?: string
    value?: number
}
export type BadgeDailyWeekdayLogin = {
    reason?: BadgeReasonEnum
    message?: string
    value?: number
}
export type BadgeDailyWeekendLogin = {
    reason?: BadgeReasonEnum
    message?: string
    value?: number
}
export type BadgeCommentWritten = {
    reason?: BadgeReasonEnum
    message?: string
    value?: number
}
export type BadgeJournalCompleted = {
    reason?: BadgeReasonEnum
    message?: string
    value?: number
}
export type BadgeClassChallengeFinishedWithImprovement = {
    reason?: BadgeReasonEnum
    message?: string
    value?: number
}
export type BadgeEasterEgg = {
    reason?: BadgeReasonEnum
    message?: string
    value?: number
}
export type BadgeMeta = {
    resource_id?: string
}
export type BadgeSchema = {
    _id?: string
    badge:
        | BadgeResourceOpened
        | BadgeResourceCompleted
        | BadgeResourcesOpened
        | BadgeDailyWeekdayLogin
        | BadgeDailyWeekendLogin
        | BadgeCommentWritten
        | BadgeJournalCompleted
        | BadgeClassChallengeFinishedWithImprovement
        | BadgeEasterEgg
    meta?: BadgeMeta
    created_at: string
    read_at?: string
}
export type AvatarSchema = {
    data?: AvatarJson
}
export type StudentResponseNoJournalEntriesSchema = {
    badges: BadgeSchema[]
    avatar: AvatarSchema
    unlocked_resources?: {
        [key: string]: string
    }
}
export type UserResponseModel = {
    _id?: string
    email: string
    username: string
    firstname: string
    lastname: string
    profile_image_url?: string
    role: Role
    school_id?: string
    school?: string
    student?: StudentResponseNoJournalEntriesSchema
    created_at: string
    updated_at: string
}
export type PageUserResponseModel = {
    items: UserResponseModel[]
    total: number
    page: number
    size: number
}
export type PlusStaffUpdateUserModel = {
    email: string
    username?: string
    firstname: string
    lastname: string
    password?: string
}
export type SchoolMainContact = {
    firstname: string
    lastname: string
    email: string
}
export type SchoolResponseModel = {
    _id?: string
    name: string
    logo_url?: string
    motto: string
    address: string
    school_size: SchoolSizeEnum
    school_type: SchoolTypeEnum
    school_main_contact: SchoolMainContact
    invited_at?: string
    setup_at?: string
    created_at: string
    updated_at: string
    deleted_at?: string
    deleted_by_user_id?: string
}
export type PageSchoolResponseModel = {
    items: SchoolResponseModel[]
    total: number
    page: number
    size: number
}
export type SchoolUpdateModel = {
    name: string
    logo_url?: string
    motto: string
    address: string
    school_size: SchoolSizeEnum
    school_type: SchoolTypeEnum
    school_main_contact: SchoolMainContact
}
export type SchoolCreateModel = {
    name: string
    logo_url?: string
    motto: string
    address: string
    school_size: SchoolSizeEnum
    school_type: SchoolTypeEnum
    school_main_contact: SchoolMainContact
}
export type JournalEntryScoringPhysicalSchema = {
    sleep: number
    water: number
    activity: number
    fruit_and_veg: number
    sugar: number
    worn_out: number
    total: number
    total_possible: number
    total_percent: number
    sleep_completed_at: string
    water_completed_at: string
    activity_completed_at: string
    fruit_and_veg_completed_at: string
    sugar_completed_at: string
    worn_out_completed_at: string
}
export type JournalEntryScoringEmotionalSchema = {
    happy: number
    positive: number
    grateful: number
    sad: number
    angry: number
    worried: number
    total: number
    total_possible: number
    total_percent: number
    happy_completed_at: string
    positive_completed_at: string
    grateful_completed_at: string
    sad_completed_at: string
    angry_completed_at: string
    worried_completed_at: string
}
export type JournalEntrySchema = {
    _id?: string
    scoring_physical: JournalEntryScoringPhysicalSchema
    scoring_emotional: JournalEntryScoringEmotionalSchema
    worst_metric: string
    best_metric: string
    total_score: number
    total_score_possible: number
    total_score_percent: number
    comment: string
    comment_ai_score?: number
    want_to_talk: number
    has_requested_support: boolean
    flagged_threshhold: number
    has_flagged: boolean
    started_at: string
    finished_at: string
    created_at: string
}
export type StudentQuestionnareSchema = {
    ive_been_feeling_optimistic_about_the_future: number
    ive_been_feeling_useful: number
    ive_been_feeling_relaxed: number
    ive_been_dealing_with_problems_well: number
    ive_been_thinking_clearly: number
    ive_been_feeling_close_to_other_people: number
    ive_been_able_to_make_up_my_own_mind_about_things: number
    started_at: string
    finished_at: string
    created_at: string
}
export type StudentSchema = {
    password_plain?: string
    badges: BadgeSchema[]
    avatar: AvatarSchema
    journal_entries: JournalEntrySchema[]
    unlocked_resources?: {
        [key: string]: string
    }
    signed_up_questionnaire?: StudentQuestionnareSchema
    follow_up_questionnaire?: StudentQuestionnareSchema
    is_sendco?: boolean
    is_premium?: boolean
}
export type UserCreateModel = {
    email: string
    username?: string
    firstname: string
    lastname: string
    password: string
    role: Role
    school_id?: string
    student?: StudentSchema
}
export type ClassAvailableStudentModel = {
    password_plain: string
}
export type ClassAvailableUserModel = {
    _id?: string
    firstname: string
    lastname: string
    username?: string
    student?: ClassAvailableStudentModel
}
export type ClassResponseModel = {
    _id?: string
    name: string
    year_group: YearGroupEnum
    students: string[] | ClassAvailableUserModel[]
    teachers: string[] | ClassAvailableUserModel[]
    created_at: string
    updated_at: string
    deleted_at?: string
    deleted_by_user_id?: string
}
export type PageClassResponseModel = {
    items: ClassResponseModel[]
    total: number
    page: number
    size: number
}
export type ClassUpdateModel = {
    name: string
    year_group: YearGroupEnum
    students: ClassAvailableUserModel[]
    teachers: ClassAvailableUserModel[]
}
export type ClassCreateModel = {
    name: string
    year_group: YearGroupEnum
    students: ClassAvailableUserModel[]
    teachers: ClassAvailableUserModel[]
}
export type SchoolManagementStudentUpdateModel = {
    email: string
    firstname: string
    lastname: string
    is_sendco?: boolean
    is_premium?: boolean
}
export type SchoolManagementStudentCreateModel = {
    email: string
    firstname: string
    lastname: string
    is_sendco?: boolean
    is_premium?: boolean
}
export type SchoolManagementTeacherUpdateModelResponse = {
    email: string
    firstname: string
    lastname: string
}
export type SchoolManagementTeacherUpdateModel = {
    email: string
    firstname: string
    lastname: string
    password?: string
}
export type SchoolManagementTeacherCreateModel = {
    email: string
    firstname: string
    lastname: string
    password: string
}
export type SchoolManagementSchoolResponseModel = {
    _id?: string
    name: string
    logo_url?: string
    motto: string
    address: string
    school_size: SchoolSizeEnum
    school_type: SchoolTypeEnum
    school_main_contact: SchoolMainContact
    invited_at?: string
    setup_at?: string
    created_at: string
    updated_at: string
}
export type JournalStatForStudent = {
    sleep: number
    water: number
    activity: number
    fruit_and_veg: number
    sugar: number
    worn_out: number
    physical_wellbeing_score: number
}
export type JournalStatsForStudent = {
    current: JournalStatForStudent
    previous?: JournalStatForStudent
    tip?: string
}
export type CreateJournalEntryScoringPhysicalSchema = {
    sleep: number
    water: number
    activity: number
    fruit_and_veg: number
    sugar: number
    worn_out: number
    total: number
    total_possible: number
    total_percent: number
    sleep_completed_at: string
    water_completed_at: string
    activity_completed_at: string
    fruit_and_veg_completed_at: string
    sugar_completed_at: string
    worn_out_completed_at: string
}
export type CreateJournalEntryScoringEmotionalSchema = {
    happy: number
    positive: number
    grateful: number
    sad: number
    angry: number
    worried: number
    total: number
    total_possible: number
    total_percent: number
    happy_completed_at: string
    positive_completed_at: string
    grateful_completed_at: string
    sad_completed_at: string
    angry_completed_at: string
    worried_completed_at: string
}
export type CreateJournalEntrySchema = {
    scoring_physical: CreateJournalEntryScoringPhysicalSchema
    scoring_emotional: CreateJournalEntryScoringEmotionalSchema
    worst_metric: string
    best_metric: string
    total_score: number
    comment: string
    want_to_talk: number
    has_requested_support: boolean
    started_at: string
    finished_at: string
}
export type QuestionnaireTypeEnum = "signed_up_questionnaire" | "follow_up_questionnaire"
export type CreateStudentQuestionnare = {
    ive_been_feeling_optimistic_about_the_future: number
    ive_been_feeling_useful: number
    ive_been_feeling_relaxed: number
    ive_been_dealing_with_problems_well: number
    ive_been_thinking_clearly: number
    ive_been_feeling_close_to_other_people: number
    ive_been_able_to_make_up_my_own_mind_about_things: number
    started_at: string
    finished_at: string
}
export const {
    useGetMeSettingsGetQuery,
    useLazyGetMeSettingsGetQuery,
    useUpdateMeSettingsPutMutation,
    useCanSetupAccountSetupAccountSetupAccountUserIdGetQuery,
    useLazyCanSetupAccountSetupAccountSetupAccountUserIdGetQuery,
    useSetupAccountSetupAccountSetupAccountUserIdPutMutation,
    usePostCommentAiPostMutation,
    useGetUserMetaGetQuery,
    useLazyGetUserMetaGetQuery,
    useSparklineStatsSparklineGetQuery,
    useLazySparklineStatsSparklineGetQuery,
    useStatsStatsPostMutation,
    useClassReportReportsClassClassIdGetQuery,
    useLazyClassReportReportsClassClassIdGetQuery,
    useStudentInternalReportReportsStudentInternalStudentIdGetQuery,
    useLazyStudentInternalReportReportsStudentInternalStudentIdGetQuery,
    useStudentExternalReportReportsStudentExternalStudentIdGetQuery,
    useLazyStudentExternalReportReportsStudentExternalStudentIdGetQuery,
    useSchoolReportsSchoolGetQuery,
    useLazySchoolReportsSchoolGetQuery,
    useAllPublishedResourceAsPublicAllPublishedGetQuery,
    useLazyAllPublishedResourceAsPublicAllPublishedGetQuery,
    useGetPublishedResourceAsPublicPublishedIdGetQuery,
    useLazyGetPublishedResourceAsPublicPublishedIdGetQuery,
    useRegisterInterestFormsAsPublicRegisterInterestPostMutation,
    useAllPublishedSchoolInviteAsPublicIdGetQuery,
    useLazyAllPublishedSchoolInviteAsPublicIdGetQuery,
    useSetupSchoolSchoolInviteAsPublicSchoolIdPostMutation,
    useGetAllPaginatedResourceAsPlusStaffAllGetQuery,
    useLazyGetAllPaginatedResourceAsPlusStaffAllGetQuery,
    useGetResourceAsPlusStaffIdGetQuery,
    useLazyGetResourceAsPlusStaffIdGetQuery,
    useUpdateResourceAsPlusStaffIdPutMutation,
    useDeleteResourceAsPlusStaffIdDeleteMutation,
    useCreateResourceAsPlusStaffPostMutation,
    useGetAllPaginatedUserAsPlusStaffAllGetQuery,
    useLazyGetAllPaginatedUserAsPlusStaffAllGetQuery,
    useGetUserAsPlusStaffIdGetQuery,
    useLazyGetUserAsPlusStaffIdGetQuery,
    useUpdateUserAsPlusStaffIdPutMutation,
    useDeleteUserAsPlusStaffIdDeleteMutation,
    useGetAllPaginatedSchoolAsPlusStaffAllGetQuery,
    useLazyGetAllPaginatedSchoolAsPlusStaffAllGetQuery,
    useGetSchoolAsPlusStaffIdGetQuery,
    useLazyGetSchoolAsPlusStaffIdGetQuery,
    useUpdateSchoolAsPlusStaffIdPutMutation,
    useDeleteSchoolAsPlusStaffIdDeleteMutation,
    useCreateSchoolAsPlusStaffPostMutation,
    useSendInviteSchoolAsPlusStaffSchoolIdPostMutation,
    useResendTeacherInviteMiscAsPlusStaffResendTeacherInviteUserIdPostMutation,
    useResendTeacherInviteMiscAsPlusStaffResendUserInviteUserIdPostMutation,
    useFixScoresMiscAsPlusStaffFixScoresPostMutation,
    useGetAllPaginatedUserAsSchoolManagementAllGetQuery,
    useLazyGetAllPaginatedUserAsSchoolManagementAllGetQuery,
    useGetUserAsSchoolManagementIdGetQuery,
    useLazyGetUserAsSchoolManagementIdGetQuery,
    useDeleteUserAsSchoolManagementIdDeleteMutation,
    useCreateUserAsSchoolManagementPostMutation,
    useGetAllPaginatedClassAsSchoolManagementAllGetQuery,
    useLazyGetAllPaginatedClassAsSchoolManagementAllGetQuery,
    usePossibleStudentsToAddClassAsSchoolManagementPossibleStudentsForClassGetQuery,
    useLazyPossibleStudentsToAddClassAsSchoolManagementPossibleStudentsForClassGetQuery,
    usePossibleTeachersToAddClassAsSchoolManagementPossibleTeachersForClassGetQuery,
    useLazyPossibleTeachersToAddClassAsSchoolManagementPossibleTeachersForClassGetQuery,
    useGetClassAsSchoolManagementIdGetQuery,
    useLazyGetClassAsSchoolManagementIdGetQuery,
    useUpdateClassAsSchoolManagementIdPutMutation,
    useDeleteClassAsSchoolManagementIdDeleteMutation,
    useCreateClassAsSchoolManagementPostMutation,
    useGetAllPaginatedStudentAsSchoolManagementAllGetQuery,
    useLazyGetAllPaginatedStudentAsSchoolManagementAllGetQuery,
    useGetStudentAsSchoolManagementIdGetQuery,
    useLazyGetStudentAsSchoolManagementIdGetQuery,
    useUpdateStudentAsSchoolManagementIdPutMutation,
    useDeleteStudentAsSchoolManagementIdDeleteMutation,
    useGetEditStudentAsSchoolManagementEditIdGetQuery,
    useLazyGetEditStudentAsSchoolManagementEditIdGetQuery,
    useCreateStudentAsSchoolManagementPostMutation,
    useGetAllPaginatedTeacherAsSchoolManagementAllGetQuery,
    useLazyGetAllPaginatedTeacherAsSchoolManagementAllGetQuery,
    useGetTeacherAsSchoolManagementIdGetQuery,
    useLazyGetTeacherAsSchoolManagementIdGetQuery,
    useUpdateTeacherAsSchoolManagementIdPutMutation,
    useDeleteTeacherAsSchoolManagementIdDeleteMutation,
    useCreateTeacherAsSchoolManagementPostMutation,
    useUpdateSchoolSettingsAsSchoolManagementGetQuery,
    useLazyUpdateSchoolSettingsAsSchoolManagementGetQuery,
    useUpdateSchoolSettingsAsSchoolManagementPutMutation,
    useStatsJournalAsStudentStatsGetQuery,
    useLazyStatsJournalAsStudentStatsGetQuery,
    useCreateJournalAsStudentPostMutation,
    useCreateQuestionnaireAsStudentPostMutation,
    useUpdateAvatarAsStudentPutMutation,
    useAllBadgesAsStudentGetQuery,
    useLazyAllBadgesAsStudentGetQuery,
    useCreateBadgesAsStudentPostMutation,
    useReadAtBadgesAsStudentIdPutMutation,
    useUnlockedResourcesUnlockedResourcesAsStudentGetQuery,
    useLazyUnlockedResourcesUnlockedResourcesAsStudentGetQuery,
    useGetAllPaginatedStudentAsTeacherAllGetQuery,
    useLazyGetAllPaginatedStudentAsTeacherAllGetQuery,
    useGetStudentAsTeacherIdGetQuery,
    useLazyGetStudentAsTeacherIdGetQuery,
    useGetAllPaginatedClassAsTeacherAllGetQuery,
    useLazyGetAllPaginatedClassAsTeacherAllGetQuery,
    useGetClassAsTeacherIdGetQuery,
    useLazyGetClassAsTeacherIdGetQuery,
} = injectedRtkApi
