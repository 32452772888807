import * as React from "react"
import { createTheme, responsiveFontSizes } from "@mui/material/styles"
import { BaseThemeColors } from "../../baseThemeColors"

const { palette } = createTheme()

export const fontStyleTag = (
    <>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link
            href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap"
            rel="stylesheet"
        />
        <link rel="stylesheet" href="https://use.typekit.net/hku3cdj.css"></link>
    </>
)

export const themeLight = responsiveFontSizes(
    createTheme({
        palette: {
            primary: BaseThemeColors.blue,
            secondary: BaseThemeColors.green,
            warning: BaseThemeColors.orange,
            success: BaseThemeColors.green,
            text: {
                primary: BaseThemeColors.black.main,
                secondary: BaseThemeColors.black.main,
            },
            common: {
                black: BaseThemeColors.black.main,
            },
        },
        components: {
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                },
            },
            MuiUseMediaQuery: {
                defaultProps: {
                    noSsr: true,
                },
            },
            MuiTypography: {
                styleOverrides: {
                    gutterBottom: {
                        marginBottom: 16,
                    },
                },
            },
        },
        typography: {
            fontFamily: "Lato",
            h1: {
                fontWeight: 700,
                fontFamily: "proxima nova, sans-serif",
                color: BaseThemeColors.black.main,
            },
            h2: {
                fontWeight: 700,
                fontFamily: "proxima nova, sans-serif",
                color: BaseThemeColors.black.main,
            },
            h3: {
                fontWeight: 700,
                fontFamily: "proxima nova, sans-serif",
                color: BaseThemeColors.black.main,
            },
            h4: {
                fontWeight: 700,
                fontFamily: "proxima nova, sans-serif",
                color: BaseThemeColors.black.main,
            },
            h5: {
                fontWeight: 700,
                fontFamily: "proxima nova, sans-serif",
                color: BaseThemeColors.black.main,
            },
            h6: {
                fontWeight: 700,
                fontFamily: "proxima nova, sans-serif",
                color: BaseThemeColors.black.main,
            },
        },
        shape: {
            borderRadius: 12,
        },
    })
)

export const themeDark = responsiveFontSizes(
    createTheme({
        palette: {
            mode: "dark",
            primary: BaseThemeColors.gold,
            secondary: BaseThemeColors.green,
            warning: BaseThemeColors.orange,
            success: BaseThemeColors.green,
        },
        components: {
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                },
            },
            MuiUseMediaQuery: {
                defaultProps: {
                    noSsr: true,
                },
            },
            MuiTypography: {
                styleOverrides: {
                    gutterBottom: {
                        marginBottom: 16,
                    },
                },
            },
        },
        typography: {
            fontFamily: "Lato",
            h1: { fontWeight: 700, fontFamily: "proxima nova, sans-serif" },
            h2: { fontWeight: 700, fontFamily: "proxima nova, sans-serif" },
            h3: { fontWeight: 700, fontFamily: "proxima nova, sans-serif" },
            h4: { fontWeight: 700, fontFamily: "proxima nova, sans-serif" },
            h5: { fontWeight: 700, fontFamily: "proxima nova, sans-serif" },
            h6: { fontWeight: 700, fontFamily: "proxima nova, sans-serif" },
        },
        shape: {
            borderRadius: 12,
        },
    })
)
