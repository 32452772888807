import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../rootState"
import { HYDRATE } from "next-redux-wrapper"

export const emptyPublicApi = createApi({
    reducerPath: "publicApi",

    baseQuery: fetchBaseQuery({
        /**
         * Base URL - use rewrite (proxy) URL if present in ENV. Otherwise use normal endpoint.
         */
        baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL_BACKEND_PRODUCTION
            ? process.env.NEXT_PUBLIC_API_BASE_URL_BACKEND_PRODUCTION
            : process.env.NEXT_PUBLIC_BASE_DOMAIN +
              process.env.NEXT_PUBLIC_BASE_PATH +
              (typeof process.env.NEXT_PUBLIC_API_BASE_URL_BACKEND_REWRITE !== "undefined" &&
              process.env.NEXT_PUBLIC_API_BASE_URL_BACKEND_REWRITE
                  ? process.env.NEXT_PUBLIC_API_BASE_URL_BACKEND_REWRITE
                  : process.env.NEXT_PUBLIC_API_BASE_URL_BACKEND),

        /**
         * If we have a token, append to header.
         */
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState)?.auth?.authenticate?.access_token
            if (token) {
                headers.set("authorization", `Bearer ${token}`)
            }
            return headers
        },
    }),

    extractRehydrationInfo(action, { reducerPath }) {
        if (action.type === HYDRATE) {
            return action.payload[reducerPath]
        }
    },

    endpoints: () => ({}),

    keepUnusedDataFor: 60 * 5, // 5 mins
})
